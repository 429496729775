import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';

import { AppContext } from '../context/AppContext';

import axiosInstance from './AxiosConfig';

import contactPerson from "../img/contact-person.png";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const ModalConsultation = () => {
  const { t } = useTranslation();
  const { isModalOpen, closeModal } = useContext(AppContext);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [company, setCompany] = useState('');
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  const nameRef = useRef(null);
  const surnameRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(handleNameSplit, 100);
    return () => clearInterval(interval);
  }, []);

  const handleNameSplit = () => {
    if (nameRef.current && surnameRef.current) {
      const nameInput = nameRef.current.value.split(' ');
      if (nameInput.length >= 2) {
        setName(nameInput.slice(0, -1).join(' '));
        setSurname(nameInput.slice(-1).join(' '));
      }
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkbox) {
      try {
        // Základní data pro tělo požadavku
        const dataToSend = {
          name: company,
          email: email,
          message: name + " " + surname + " " + message,
          subject: "PermoniQ",
          send_email: true,
          country: window.location.pathname.split("/")[1]
        };

        // Pokud uživatel vybral datum, přidej ho do dat
        if (date) {
          dataToSend.date = date;
        }

        const response = await fetch("https://permoniq.com/api/send-message/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(dataToSend)
        });

        if (!response.ok) {
          const errorDetails = await response.json(); // Předpoklad, že server vrací JSON odpověď s chybou
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText} - ${JSON.stringify(errorDetails)}`);
        }

      } catch (error) {
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
          errorObject: error
        });
      }
    }
  };




  return (
    <div className={`modal ${isModalOpen ? 'is-open' : ''}`} id={t('consultation.id')}>
      <div className="modal__body">
        <div className="modal__close" onClick={closeModal}></div>
        <div className="modal__content">
          <div className="box">
            <h2 dangerouslySetInnerHTML={renderHTML(t('consultation.title'))}></h2>
            <p dangerouslySetInnerHTML={renderHTML(t('consultation.description'))}></p>
            <form action="#" autoComplete='off' method="post" className="form" onSubmit={handleSubmit}>
              <p>
                <label htmlFor="contact-email" dangerouslySetInnerHTML={renderHTML(t('consultation.emailLabel'))}></label>
                <input
                  type="email"
                  id="contact-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </p>
              <div className="form__column">
                <p>
                  <label htmlFor="contact-name" dangerouslySetInnerHTML={renderHTML(t('consultation.nameLabel'))}></label>
                  <input
                    type="text"
                    id="contact-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    ref={nameRef}
                  />
                </p>
                <p>
                  <label htmlFor="contact-surname" dangerouslySetInnerHTML={renderHTML(t('consultation.surnameLabel'))}></label>
                  <input
                    type="text"
                    id="contact-surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    required
                    ref={surnameRef}
                  />
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="contact-company" dangerouslySetInnerHTML={renderHTML(t('consultation.companyLabel'))}></label>
                  <input
                    type="text"
                    id="contact-company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </p>
                <p>
                  <label htmlFor="contact-date" dangerouslySetInnerHTML={renderHTML(t('consultation.dateLabel'))}></label>
                  <input
                    type="date"
                    id="contact-date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </p>
              </div>
              <p>
                <label htmlFor="contact-message" dangerouslySetInnerHTML={renderHTML(t('consultation.messageLabel'))}></label>
                <textarea
                  id="contact-message"
                  rows="4"
                  cols="50"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </p>
              <p>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="contact-checkbox"
                    id="contact-checkbox"
                    checked={checkbox}
                    onChange={(e) => setCheckbox(e.target.checked)}
                    required
                  />
                  <span className="checkbox__label" dangerouslySetInnerHTML={renderHTML(t('consultation.checkboxLabel'))}></span>
                  <span className="checkbox__checker"></span>
                </label>
              </p>
              <p className="form__submit">
                <input type="submit" value={he.decode(t('consultation.submitButton'))} className="btn" disabled={!checkbox} />
              </p>
            </form>
          </div>
          <div className="box is-connected center">
            <p><img src={contactPerson} alt="" width="120" height="137" /></p>
            <p className="modal__contact">
              <span className="icon icon--phone">
                <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <use xlinkHref="/img/icons.svg#phone" x="0" y="0" width="100%" height="100%"></use>
                </svg>
              </span>
              <a href={t("permoniqPhoneHref")}>{t("permoniqPhone")}</a>
              <small>{t('consultation.phoneHours')}</small>
            </p>
            <p className="modal__contact">
              <span className="icon icon--email">
                <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <use xlinkHref="/img/icons.svg#email" x="0" y="0" width="100%" height="100%"></use>
                </svg>
              </span>
              <a href="mailto:info@permoniq.com" dangerouslySetInnerHTML={renderHTML(t('consultation.email'))}></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConsultation;
