import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// import useRegisterUserDetails from '../hooks/useRegisterUserDetails';
import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';
import ProgressTracker from '../components/ProgressTracker';

import axiosInstance from '../components/AxiosConfig';

import Cookies from 'js-cookie';

import useUserRegisterDetails from '../hooks/useUserRegisterDetails';

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
    cs: csFlag,
    en: enFlag,
    sk: skFlag,
    pl: plFlag,
    hu: huFlag,
    ro: roFlag,
};

const phonePrefixes = {
    cs: '+420',
    sk: '+421',
    pl: '+48',
    hu: '+36',
    ro: '+40'
};

const RegisterShop = () => {
    const [shopForRegistration, setShopForRegistration] = useState([])
    const [formData, setFormData] = useState({});
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [isCountryOpen, setIsCountryOpen] = useState(false);
    const [country, setCountry] = useState('cs');
    const [description, setDescription] = useState("")
    const [borderColor, setBorderColor] = useState("");
    const textareaRef = useRef(null);
    const [charCount, setCharCount] = useState(0);
    const maxLength = 10000;
    const {
        userData,
        setUserData,
        uuid,
        setUuid,
        isAdmin,
        isAlertVisible,
        showAlert,
        closeAlert,
        alertMessage,
        steps,
        selectedShop
    } = useContext(AppContext);

    const { t } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();

    useUserRegisterDetails()

    window.dataLayer = window.dataLayer || []

    useEffect(() => {

        if (Object.keys(userData).length > 0 && Object.keys(userData.shops).length > 0) {
            if (isAdmin) {
                if (uuid) {
                    setShopForRegistration(userData.shops.find(oneShop => oneShop.uuid === uuid))

                    const shopInStep1 = userData.shops.find(oneShop => oneShop.uuid === uuid);

                    const { website, phone, first_name, last_name, country, description } = selectedShop ?? {};
                    setFormData({
                        website,
                        phone,
                        first_name,
                        last_name,
                        country,
                        description
                    });
                }
            } else {
                if (uuid) {
                    setShopForRegistration(userData.shops.find(oneShop => oneShop.uuid === uuid))
                }

                // Najděte obchod, který má status_create rovný "step1"
                const shopInStep1 = userData.shops.find(shop => shop.status_create === "step1");

                if (shopInStep1 && Object.keys(shopInStep1).length > 0) {
                    const { website, phone, first_name, last_name, country, description } = shopInStep1;
                    setFormData({
                        website,
                        phone,
                        first_name,
                        last_name,
                        country,
                        description
                    });
                }
            }
        }
    }, [userData, isAdmin]);

    const isIpAddress = (hostname) => {
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(hostname);
    };

    const validateAndCleanUrl = (url) => {
        try {
            if (!url.startsWith("http")) {
                url = `https://${url}`;
            }
            const urlObj = new URL(url);
            if (isIpAddress(urlObj.hostname)) {
                return false;
            }
            if (urlObj.hostname.includes('shoptet') || urlObj.hostname.includes('upgates')) {
                return false;
            }
            const hostnameParts = urlObj.hostname.split('.');
            if (hostnameParts.length >= 2) {
                const tld = hostnameParts.slice(-2).join('.');
                return `https://${hostnameParts.join('.')}`;
            }
        } catch (error) {
            return false;
        }
        return false;
    };

    const handleInputChange = (key) => (e) => {
        const { value } = e.target;

        // Pokud se jedná o změnu v popisu, aktualizuj charCount a borderColor
        if (key === "description") {
            setCharCount(value.length);
            if (value.length < 350) {
                setBorderColor("red");
            } else {
                setBorderColor("green");
            }
        }

        setFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };



    const handleUrlBlur = () => {
        if (formData.website) {
            const cleanedUrl = validateAndCleanUrl(formData.website);
            if (cleanedUrl) {
                setIsUrlValid(true);
                setFormData(prevState => ({
                    ...prevState,
                    website: cleanedUrl
                }));
            } else {
                setIsUrlValid(false);
            }
        } else {
            setIsUrlValid(true);
        }
    };

    const toggleCountry = () => {
        setIsCountryOpen(!isCountryOpen);
    };

    const handleCountryChange = (lang) => {
        setCountry(lang);
        setIsCountryOpen(false); // Close the dropdown after selecting a country
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowErrors(true);

        // Přidání předvolby k telefonnímu číslu, pokud není kompletní
        let phone = formData.phone.replace(/\s+/g, ''); // Odstranit mezery
        const selectedPrefix = phonePrefixes[country];

        if (phone.startsWith(selectedPrefix)) {
            // Pokud telefonní číslo začíná správnou předvolbou, ale chybí '+'
            if (!phone.startsWith('+')) {
                phone = `+${phone}`;
            }
        } else if (phone.startsWith(selectedPrefix.slice(1))) {
            // Pokud telefonní číslo začíná správnou předvolbou, ale bez '+'
            phone = `+${phone}`;
        } else if (!phone.startsWith('+')) {
            // Pokud telefonní číslo nezačíná žádnou předvolbou, přidáme předvolbu pro zvolenou zemi
            phone = `${selectedPrefix}${phone}`;
        }

        // Validace telefonního čísla
        const isPhoneValid = /^\+\d+$/.test(phone);

        if (formData.website && isPhoneValid && formData.first_name && formData.last_name && isUrlValid) {
            const determineCurrency = (country) => {
                return country === "cs" ? "CZK" :
                    country === "hu" ? "HUF" :
                        country === "sk" ? "EUR" :
                            country === "ro" ? "RON" :
                                country === "pl" ? "PLN" :
                                    "CZK"; // Výchozí hodnota, pokud je potřeba
            };

            const firstStepRegistration = {
                website: formData.website,
                phone: phone, // Použití upraveného telefonního čísla
                first_name: formData.first_name,
                last_name: formData.last_name,
                country: country,
                currency: determineCurrency(country), // Použití vrácené hodnoty z funkce
                status_create: "billinfo",
                description: formData.description
            };

            if (uuid && selectedShop.uuid === uuid) {
                console.log('Sending update data:', firstStepRegistration);
                axiosInstance.patch(`shop/update/${uuid}/`, firstStepRegistration)
                    .then(response => {
                        console.log('Registration successful:', response.data);
                        window.scrollTo(0, 0);
                        navigate(`/${lng}/app/company-details/`);
                    })
                    .catch(error => {
                        console.error('Error response:', error.response?.data || error.message);
                        showAlert(error.response?.data || error.message, "danger");
                    });
            } else {
                console.log('Sending registration data:', firstStepRegistration);
                axiosInstance.post('shop/create/', firstStepRegistration)
                    .then(response => {
                        console.log('Registration successful:', response.data);
                        window.dataLayer.push({
                            event: "register_shop"
                        })
                        console.log("pk", response.data.pk)
                        sessionStorage.setItem("x23uU09I098D", response.data.pk)
                        setUuid(response.data.pk)
                        navigate(`/${lng}/app/company-details/`);
                    })
                    .catch(error => {
                        console.error('Error response:', error.response?.data || error.message);
                        showAlert(error.response?.data || error.message, "danger");
                    });
            }
        } else {
            showAlert(t('registerForm.fillAllFields'), "danger");
        }
    };

    // Přizpůsobení labels v steps
    const localizedSteps = steps.map(step => ({
        ...step,
        label: t(`${step.label}`),
        alertMessage: t(step.alertMessage),
    }));

    return (
        <>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/register-shop/`) : () => showAlert(t("registerForm.contactSupport"), "info")} className="is-active" ><strong>1.</strong> {t('registerProgress.addShop')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/company-details/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>2.</strong> {t('registerProgress.companyDetails')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/upload-products/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>3.</strong> {t('registerProgress.uploadProducts')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/connect-google/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>4.</strong> {t('registerProgress.connectGoogle')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/install-tracking-code/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>5.</strong> {t('registerProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

            <ProgressTracker steps={localizedSteps} />

            <section className="section register">
                <article className="container">
                    <h1 className="title">{t('registerTitle')}</h1>
                    <form action="#" method="post" autoComplete='off' className="form" onSubmit={handleSubmit}>
                        <div className="box">
                            <p>
                                <label htmlFor="url">{t('registerForm.url')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                <input
                                    type="text"
                                    id="url"
                                    value={formData.website || ''} // Ensure the value is not undefined
                                    onChange={handleInputChange("website")}
                                    onBlur={handleUrlBlur}
                                    required
                                />
                                {!isUrlValid && formData.website && showErrors && <span className="error">{t('registerForm.invalidUrl')}</span>}
                                {showErrors && !formData.website && <span className="error">{t('registerForm.fillAllFields')}</span>}
                            </p>

                            <div className="form__column">
                                <div className="form__p">
                                    <label htmlFor="country">{t('registerForm.country')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <div className={`country ${isCountryOpen ? 'is-open' : ''}`} onClick={toggleCountry}>
                                        <div className="country__selected" data-lang={selectedShop?.country || country}>
                                            {t(`registerForm.countries.${selectedShop?.country || country}`)}
                                        </div>

                                        <ul className="country__switch">
                                            {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                                <li data-lang={lang} key={lang} onClick={() => handleCountryChange(lang)}>{t(`registerForm.countries.${lang}`)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <select
                                        name="country"
                                        id="country"
                                        className="country__select"
                                        value={selectedShop?.country || country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                            <option value={lang} key={lang}>{t(`registerForm.countries.${lang}`)}</option>
                                        ))}
                                    </select>
                                </div>
                                <p>
                                    <label htmlFor="phone">{t('registerForm.phone')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        value={formData.phone || ''} // Ensure the value is not undefined
                                        onChange={handleInputChange("phone")}
                                        required
                                    />
                                    {showErrors && phoneError && <span className="error">{phoneError}</span>}
                                    {showErrors && !formData.phone && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                            </div>

                            <div className="form__column">
                                <p>
                                    <label htmlFor="name">{t('registerForm.name')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={formData.first_name || ''} // Ensure the value is not undefined
                                        onChange={handleInputChange("first_name")}
                                        required
                                    />
                                    {showErrors && !formData.first_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                                <p>
                                    <label htmlFor="surname">{t('registerForm.surname')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="text"
                                        id="surname"
                                        value={formData.last_name || ''} // Ensure the value is not undefined
                                        onChange={handleInputChange("last_name")}
                                        required
                                    />
                                    {showErrors && !formData.last_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                            </div>
                            <div>
                                <label htmlFor="about-us">
                                    {t("registerForm.descAboutShop")}
                                </label>
                                <textarea
                                    id="about-us"
                                    rows="5"
                                    cols="40"
                                    placeholder={t("registerForm.descPlaceholder")}
                                    maxLength={maxLength}
                                    minLength={350}
                                    value={formData.description || ""}
                                    style={{
                                        resize: "none",
                                        borderColor: borderColor, // Dynamicky nastav barvu ohraničení
                                        borderWidth: "2px",
                                    }}
                                    onChange={handleInputChange("description")}
                                />
                                <p style={{textAlign: "right"}}>{charCount}/{maxLength} znaků</p> {/* Počet znaků */}
                            </div>
                            <sub style={{ color: 'red', fontSize: "1.5rem" }}>*<em style={{ color: "black", fontSize: "1rem" }}>{t("requireItem")}</em></sub>
                        </div>

                        <div className="register__submit">
                            <p>
                                <button type="submit" className="btn">{t('registerForm.submit')}</button>
                            </p>
                        </div>
                    </form>
                </article>
            </section>
        </>
    );
};

export default RegisterShop;
