import React, { useContext, useEffect } from 'react';

import { AppContext } from '../context/AppContext';

import AppMenu from '../components/AppMenu';
import AppDashboard from '../components/AppDashboard';
import AppStatistics from '../components/AppStatistics';
import AppCampaigns from '../components/AppCampaigns';
import AppCredit from '../components/AppCredit';
import AppSettings from '../components/AppSettings';
import AppHelp from '../components/AppHelp';
import AppContactUs from '../components/AppContactUs';

const AppContent = () => {
    const {
        appContentType
    } = useContext(AppContext);

    return (
        <main className="content content--app">
            <section className="container">
                <AppMenu />
                {appContentType === 'dashboard' && <AppDashboard />}
                {appContentType === "statistics" && <AppStatistics />}
                {appContentType === 'campaigns' && <AppCampaigns />}
                {appContentType === "credit" && <AppCredit />}
                {appContentType === "settings" && <AppSettings />}
                {appContentType === "help" && <AppHelp />}
                {appContentType === "contact-us" && <AppContactUs />}
            </section>
        </main>
    );
};

export default AppContent;
