import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';

import axiosInstance from './AxiosConfig';

import successIcon from '../img/icons/success.svg';
import dangerIcon from '../img/icons/danger.svg';
import { ReactSVG } from 'react-svg';

import firstImg from "../img/credit/1.png"
import secondImg from "../img/credit/2.png"
import thirdImg from "../img/credit/3.png"
import fourthImg from "../img/credit/4.png"

const AppCredit = () => {
    const {t} = useTranslation()

    const navigate = useNavigate();
    const { userData, setAppContentType, setActiveItem } = useContext(AppContext);
    const [accountStatus, setAccountStatus] = useState('inactive'); // Možnosti: 'inactive', 'active'

    const onRedirect = (where) => {
        window.scrollTo(0, 0);
        navigate(`/app/${where}/`);
        setActiveItem(where);
        setAppContentType(where);
    };

    return (
        <article className="app-content">
            <h1>{t('appCredit.title')}</h1>

            <section className="credit-info">
                <p>
                    {t('appCredit.creditInfo.description')}
                </p>
            </section>

            <section className="how-to">
                <h2>{t('appCredit.howTo.title')}</h2>
                <p>
                    {t('appCredit.howTo.description')}
                </p>
            </section>

            <section className="billing">
                <h2>{t('appCredit.billing.title')}</h2>
                <ol>
                    <li>{t('appCredit.billing.steps.0')}</li>
                    <img src={firstImg} alt="ads-tutor" style={{ display: 'block', margin: '20px auto', width: '80%' }} />
                    <li>{t('appCredit.billing.steps.1')}</li>
                    <li>{t('appCredit.billing.steps.2')}</li>
                    <img src={secondImg} alt="ads-tutor" style={{ display: 'block', margin: '20px auto', width: '50%' }} />
                    <li>{t('appCredit.billing.steps.3')}</li>
                    <img src={thirdImg} alt="ads-tutor" style={{ display: 'block', margin: '20px auto', width: '80%' }} />
                </ol>
                <p>
                    {t('appCredit.billing.advice')}
                </p>
            </section>

            <section className="automatic-payments">
                <h2>{t('appCredit.automaticPayments.title')}</h2>
                <p>
                    {t('appCredit.automaticPayments.description')}
                </p>
                <p>
                    {t('appCredit.automaticPayments.currentLimit')}
                </p>
                <img src={fourthImg} alt="ads-tutor" style={{ display: 'block', margin: '20px auto', width: '80%' }} />
            </section>
        </article>
    );
};

export default AppCredit;
