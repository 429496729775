import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';
import ModalLoading from '../components/ModalLoading';
import ProgressTracker from '../components/ProgressTracker';

import axiosInstance from '../components/AxiosConfig';

import gtmLogo from '../img/logos/logo-gtm.png';
import shoptetLogo from '../img/logos/logo-shoptet.png';
import upgatesLogo from '../img/logos/logo-upgates.png';
import shopifyLogo from "../img/logos/logo-shopify.png"
import woocommerceLogo from "../img/logos/logo-woocommerce.png"
import prestashopLogo from "../img/logos/logo-prestashop.png"
import eshopRychleLogo from "../img/logos/logo-eshop-rychle.png"
import useUserRegisterDetails from '../hooks/useUserRegisterDetails';


const InstallTrackingCode = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const [shopInPixel, setShopInPixel] = useState({})
  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});

  const {
    uuid,
    userData,
    setUserData,
    code,
    setCode,
    isUserDataLoading,
    setIsUserDataLoading,
    setVisitedInstallTrackingCode,
    appContentType,
    steps,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    selectedShop,
    setSelectedShop
  } = useContext(AppContext);

  useUserRegisterDetails()

  const answerRefs = useRef([]);
  const copyButtonRef = useRef(null);
  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];

  let gtmSteps = t('installTrackingCodeForm.gtmSteps', { returnObjects: true });
  let shoptetSteps = t('installTrackingCodeForm.shoptetSteps', { returnObjects: true });
  let upgatesSteps = t('installTrackingCodeForm.upgatesSteps', { returnObjects: true });
  let shopifySteps = t('installTrackingCodeForm.shopifySteps', { returnObjects: true });
  let woocommerceSteps = t('installTrackingCodeForm.woocommerceSteps', { returnObjects: true });
  let prestashopSteps = t('installTrackingCodeForm.prestashopSteps', { returnObjects: true });
  let eshopRychleSteps = t('installTrackingCodeForm.eshopRychleSteps', { returnObjects: true });

  gtmSteps = Array.isArray(gtmSteps) ? gtmSteps : [];
  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
  shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
  woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
  prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
  eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];

  useEffect(() => {
    console.log(1)
    if (Object.keys(userData).length > 0 && Object.keys(userData.shops).length > 0) {
      console.log(2)
      if (uuid) {
        console.log(3)
        setSelectedShop(userData.shops.find(oneShop => oneShop.uuid === uuid));
      }
    }
  }, [uuid])

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     setIsUserDataLoading(true);
  //     try {
  //       const { data } = await axiosInstance.get(`shop/detail/${urlID}/`);
  //       setShop(data);
  //       setCountry(lng === 'en' ? 'cs' : lng);
  //     } catch (error) {
  //       console.error('Error fetching user details:', error);
  //       navigate(`/404/`);
  //     } finally {
  //       setIsUserDataLoading(false);
  //     }
  //   };

  //   fetchUserData();
  // }, [urlID, lng, navigate]);

  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!code.trim()) {
      showAlert('Please add the tracking code');
      return;
    }
    window.scrollTo(0, 0);
    // navigate(`/${lng}/app/next-page/${urlID}/`); // Adjust this to your next page
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      showAlert(t('installTrackingCodeForm.codeCopied'), "info");
      copyButtonRef.current.blur();
    });
  };

  const updateShop = async (updates) => {
    try {
      setVisitedInstallTrackingCode(true)
      // Aktualizace konkrétního obchodu v poli `shops`
      const updatedShops = userData.shops.map(shop =>
        shop.uuid === urlID ? { ...shop, ...updates } : shop
      );

      // Aktualizace stavu `userData` s novým polem `shops`
      setUserData(prevState => ({
        ...prevState,
        shops: updatedShops
      }));

      // Odeslání aktualizovaných dat na server
      await axiosInstance.patch(`shop/update/${uuid}/`, updates);
      console.log("Shop data updated", updates);
    } catch (error) {
      console.error("Failed to update shop data", error.response);
    }
  };

  const handleFinish = async () => {
    setIsUserDataLoading(true);
    let isConnected = false

    if (!selectedShop.google_analytics) {
      try {

        // Vytvoření Google Analytics účtu
        let analyticsData;
        try {
          const { data } = await axiosInstance.post(`googleanalytics/account/create/`, {
            customer_name: selectedShop.website.replace(/^https?:\/\//, ''),
            currency_code: selectedShop.currency,
            customer_url: selectedShop.website
          });
          analyticsData = data;
          console.log("Google Analytics account created:", analyticsData);
        } catch (error) {
          console.error("Error creating Google Analytics account:", error);
          showAlert(error.response?.data?.message || t("installTrackingCodeForm.createAnalyticsError"));
          return;  // Ukončíme proces, pokud tento request selže
        }

        // updateShop - aktualizace obchodu s Google Analytics ID
        try {
          await updateShop({ google_analytics: analyticsData.customer_id, status_create: "end" });
          console.log("Shop updated with Google Analytics ID:", analyticsData.customer_id);
        } catch (error) {
          console.error("Error updating shop with Google Analytics ID:", error);
          showAlert(error.response?.data?.message || t('installTrackingCodeForm.updateShopError'));
          return;  // Ukončíme proces, pokud tento request selže
        }

        // Propojení Google Analytics s Google Ads
        try {
          if (analyticsData.customer_id) {
            axiosInstance.post(`googleanalytics/connect/googleads/`, {
              google_analytics_id: analyticsData.customer_id,
              google_ads_id: selectedShop.google_ads
            });
            console.log('Google Analytics and Google Ads connected successfully');

          }
        } catch (error) {
          console.error("Error connecting Google Analytics with Google Ads:", error);
          showAlert(error.response?.data?.message || t("installTrackingCodeForm.connectAnalyticsAdsError"));
          return;  // Ukončíme proces, pokud tento request selže
        }



      } catch (error) {
        console.error("Error during finish setup:", error);
        showAlert(error.response?.data?.message || t("installTrackingCodeForm.proccessError"));
      } finally {
        navigate(`/${lng}/app/${appContentType}/`);
        setIsUserDataLoading(false);
      }
    } else {
      navigate(`/${lng}/app/${appContentType}/`);
      setIsUserDataLoading(false);
    }

  };

  // Přizpůsobení labels v steps
  const localizedSteps = steps.map(step => ({
    ...step,
    label: t(`${step.label}`),
    alertMessage: t(step.alertMessage),
  }));

  const handleScroll = (event) => {
    event.preventDefault();
    const element = document.getElementById('code');
    const offset = -100; // například 100px nad prvek
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {isUserDataLoading && <ModalLoading />}
      {/* <div className="progress">
        <div className="container">
          <ul>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>1.</strong> {t('installTrackingCodeProgress.addShop')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>2.</strong> {t('installTrackingCodeProgress.companyDetails')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>3.</strong> {t('installTrackingCodeProgress.uploadProducts')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>4.</strong> {t('installTrackingCodeProgress.connectGoogle')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-active"><strong>5.</strong> {t('installTrackingCodeProgress.installCode')}</li>
          </ul>
        </div>
      </div> */}

      <ProgressTracker steps={localizedSteps} />

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('installTrackingCodeTitle')}</h1>
          <form action="#" method="post" autoComplete='off' className="form" onSubmit={handleSubmit}>
            <div className="box">
              <p>
                <label htmlFor="code">{t('installTrackingCodeForm.codeInstructions')}</label>
                <textarea
                  name="code"
                  id="code"
                  className="form__code"
                  cols="30"
                  rows="3"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </p>
              <p>
                <button className="btn js-copy" type="button" onClick={copyToClipboard} ref={copyButtonRef}>{t('installTrackingCodeForm.copyCode')}</button>
              </p>

              <br />

              <p>{t('installTrackingCodeForm.helpTitle')}</p>

              <div className="faq faq--compact">

                <div className={`faq__item ${openIndices.includes(1) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(1)}>
                    <img className='logo__icon' src={shoptetLogo} alt={t('installTrackingCodeForm.shoptetTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[1] = el)}
                    style={{ maxHeight: openIndices.includes(1) ? `${heights[1]}px` : '0' }}
                  >
                    <ol>
                      {shoptetSteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus </a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>

                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(2) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(2)}>
                    <img className='logo__icon' src={upgatesLogo} alt={t('installTrackingCodeForm.upgatesTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[2] = el)}
                    style={{ maxHeight: openIndices.includes(2) ? `${heights[2]}px` : '0' }}
                  >
                    <ol>
                      {upgatesSteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus</a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(3) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(3)}>
                    <img className='logo__icon' src={shopifyLogo} alt={t('installTrackingCodeForm.shopifyTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[3] = el)}
                    style={{ maxHeight: openIndices.includes(3) ? `${heights[3]}px` : '0' }}
                  >
                   <ol>
                      {shopifySteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus</a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(4) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(4)}>
                    <img className='logo__icon' src={woocommerceLogo} alt={t('installTrackingCodeForm.woocommerceTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[4] = el)}
                    style={{ maxHeight: openIndices.includes(4) ? `${heights[4]}px` : '0' }}
                  >
                    <ol>
                      {woocommerceSteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus</a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(5) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(5)}>
                    <img className='logo__icon' src={prestashopLogo} alt={t('installTrackingCodeForm.prestashopTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[5] = el)}
                    style={{ maxHeight: openIndices.includes(5) ? `${heights[5]}px` : '0' }}
                  >
                    <ol>
                      {prestashopSteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus </a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(6) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(6)}>
                    <img className='logo__icon' src={eshopRychleLogo} alt={t('installTrackingCodeForm.eshopRychleTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[6] = el)}
                    style={{ maxHeight: openIndices.includes(6) ? `${heights[6]}px` : '0' }}
                  >
                    <ol>
                      {eshopRychleSteps.map((step, index) => (
                        <li key={index}>
                          {step.split(" ").map((word, wordIndex) =>
                            word.includes("DataPlus") ? (
                              <a key={wordIndex} href="#code" onClick={handleScroll} className='dp--anchor'>DataPlus </a>
                            ) : (
                              <span key={wordIndex}>{word} </span>
                            )
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(0) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(0)}>
                    <img className='logo__icon' src={gtmLogo} alt={t('installTrackingCodeForm.gtmTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[0] = el)}
                    style={{ maxHeight: openIndices.includes(0) ? `${heights[0]}px` : '0' }}
                  >
                    <ol>
                      {gtmSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="register__submit">
              <p>
                <button className="btn" type="submit" onClick={handleFinish}>{t('installTrackingCodeForm.finish')}</button>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );

};

export default InstallTrackingCode;
