import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';

import { AppContext } from '../context/AppContext';  // Importujeme náš AppContext

import colemansiLogo from "../img/logos/logo-colemansi.png";
import atosElektroLogo from "../img/logos/logo-atos-elektro.png";
import feProduktLogo from "../img/logos/logo-feprodukt.svg";
import blancheporteLogo from "../img/logos/logo-blancheporte.svg";
import nejlevnejsiZboziLogo from "../img/logos/logo-nejlevnejsi-zbozi.png";
import klient1Logo from '../img/clients/kamil-kamilka.jpg';
import klient2Logo from '../img/clients/jana-novakova.jpg';

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const clients = [
    {
        logo: colemansiLogo,
        alt: 'references.clients.colemansi'
    },
    {
        logo: atosElektroLogo,
        alt: 'references.clients.atoselektro'
    },
    {
        logo: feProduktLogo,
        alt: 'references.clients.feprodukt'
    },
    {
        logo: blancheporteLogo,
        alt: 'references.clients.blancheporte'
    },
    {
        logo: nejlevnejsiZboziLogo,
        alt: 'references.clients.nejlevnejsiZbozi'
    }
];

const testimonials = [
    {
        text: 'references.testimonials.kamil.quote',
        img: klient1Logo,
        alt: 'references.testimonials.kamil.name',
        name: 'references.testimonials.kamil.name',
        title: 'references.testimonials.kamil.position'
    },
    {
        text: 'references.testimonials.jana.quote',
        img: klient2Logo,
        alt: 'references.testimonials.jana.name',
        name: 'references.testimonials.jana.name',
        title: 'references.testimonials.jana.position'
    },
    {
        text: 'references.testimonials.petr.quote',
        img: klient1Logo,
        alt: 'references.testimonials.petr.name',
        name: 'references.testimonials.petr.name',
        title: 'references.testimonials.petr.position'
    },
    {
        text: 'references.testimonials.lucie.quote',
        img: klient2Logo,
        alt: 'references.testimonials.lucie.name',
        name: 'references.testimonials.lucie.name',
        title: 'references.testimonials.lucie.position'
    },
    {
        text: 'references.testimonials.martin.quote',
        img: klient1Logo,
        alt: 'references.testimonials.martin.name',
        name: 'references.testimonials.martin.name',
        title: 'references.testimonials.martin.position'
    },
    {
        text: 'references.testimonials.anna.quote',
        img: klient2Logo,
        alt: 'references.testimonials.anna.name',
        name: 'references.testimonials.anna.name',
        title: 'references.testimonials.anna.position'
    }
];

const ReferenceSection = () => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const { openModal } = useContext(AppContext);  // Použití funkce z kontextu pro otevření modalu

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 2 : prevIndex - 2 < 0 ? testimonials.length - 2 : prevIndex - 2));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 2 ? 0 : prevIndex + 2 >= testimonials.length ? 0 : prevIndex + 2));
    };

    const getVisibleTestimonials = () => {
        const secondIndex = currentIndex + 1 >= testimonials.length ? 0 : currentIndex + 1;
        return [testimonials[currentIndex], testimonials[secondIndex]];
    };

    return (
        <section className="section" id={t('paths.references')}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('references.title'))}></h2>
                <div className="reference">
                    <div className="box reference__1">
                        <h3 dangerouslySetInnerHTML={renderHTML(t('references.clients.title'))}></h3>
                        <ul>
                            {clients.map((client, index) => (
                                <li key={index}>
                                    <img src={client.logo} alt={t(client.alt)} width="160" height="64" loading="lazy" />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <div className="slider is-connected">
                        <div className="swiper">
                            <div className="swiper-wrapper">
                                {getVisibleTestimonials().map((testimonial, index) => (
                                    <div className="swiper-slide" key={index}>
                                        <div className="box client">
                                            <span className="icon icon--cite">
                                                <svg className="icon__svg">
                                                    <use href="/img/icons.svg#cite" x="0" y="0" width="100%" height="100%"></use>
                                                </svg>
                                            </span>
                                            <p className="client__say" dangerouslySetInnerHTML={renderHTML(t(testimonial.text))}></p>
                                            <div className="client__person">
                                                <p className="avatar">
                                                    <img src={testimonial.img} alt={t(testimonial.alt)} width="500" height="500" loading="lazy" />
                                                </p>
                                                <p>
                                                    <strong>{t(testimonial.name)}</strong>
                                                    {t(testimonial.title)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="slider-arrow-prev" onClick={handlePrevClick}></div>
                        <div className="slider-arrow-next" onClick={handleNextClick}></div>
                    </div> */}
                </div>
                <p className="section__more">
                    <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('references.ctaTryFree'))}></a>
                    <a href={`#${t('paths.consultation')}`} className="btn btn--inverse js-modal" onClick={openModal} dangerouslySetInnerHTML={renderHTML(t('references.ctaConsultation'))}></a>
                </p>
            </article>
        </section>
    );
};

export default ReferenceSection;
