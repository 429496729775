import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';

const NoPNOWarning = () => {
    const { t } = useTranslation()

    const {
        setAppContentType,
        setActiveItem
    } = useContext(AppContext)

    return (
        <div className="alert alert--danger" style={{
            zIndex: "10000000000",
            position: "fixed",
            bottom: 0,
            width: "100%",
            margin: 0,
            textAlign: "center"
        }}>
            <p>{t("NoPNOWarning.message")}<strong><a
            className='noPNo_anchor'
                onClick={() => {
                    setAppContentType("settings")
                    setActiveItem("settings")
                }}>{t("NoPNOWarning.goHere")}</a></strong></p>
        </div>
    );
};

export default NoPNOWarning;
