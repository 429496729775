import React, { useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';

import axiosInstance from './AxiosConfig';

import useUserAppDetails from '../hooks/useUserAppDetails';

import ModalLoading from './ModalLoading';
import SimpleAlert from './SimpleAlert';
import { useParams } from 'react-router-dom';

const currencySettings = {
    CZK: {
        symbol: 'Kč',
        minLimit: 100,
        maxLimit: 20000,
        baseAmount: 10000
    },
    EUR: {
        symbol: '€',
        minLimit: 5,
        maxLimit: 800,
        baseAmount: 400 // přibližný ekvivalent 10000 Kč
    },
    HUF: {
        symbol: 'Ft',
        minLimit: 2000,
        maxLimit: 800000,
        baseAmount: 142857 // přibližný ekvivalent 10000 Kč
    },
    RON: {
        symbol: 'lei',
        minLimit: 20,
        maxLimit: 3200,
        baseAmount: 2000 // přibližný ekvivalent 10000 Kč
    },
    PLN: {
        symbol: 'zł',
        minLimit: 20,
        maxLimit: 3200,
        baseAmount: 1667 // přibližný ekvivalent 10000 Kč
    }
};



const AppSettings = () => {
    const { t } = useTranslation();

    const [shopForDisplay, setShopForDisplay] = useState({});
    const [codeVisible, setCodeVisible] = useState(false);
    const [rangeValue, setRangeValue] = useState(20);
    const [prevRangValue, setPrevRangeValue] = useState(null)
    const [limitValue, setLimitValue] = useState(100);
    const [prevLimitValue, setPrevLimitValue] = useState(null)
    const [productFeedUrl, setProductFeedUrl] = useState('');
    const [managerEmail, setManagerEmail] = useState('priklad@email.com');
    const maxLength = 10000;
    const [descValue, setDescValue] = useState("")
    const [currency, setCurrency] = useState('CZK');

    const [userList, setUserList] = useState([
        'martin.skocdopole@gmail.com',
        'jan.novak@email.cz'
    ]);
    const [feedUrlError, setFeedUrlError] = useState('');

    const copyButtonRef = useRef(null);

    const currentCurrencySettings = currencySettings[currency] || currencySettings['CZK'];

    const {
        code,
        setCode,
        userData,
        uuid,
        selectedShop,
        pno,
        setPno,
        shopName,
        merchantId,
        isUserDataLoading,
        setIsUserDataLoading,
        campaignId,
        budget,
        setBudget,
        alertMessage,
        isAlertVisible,
        showAlert,
        closeAlert,
    } = useContext(AppContext);

    useUserAppDetails()

    // uuid se propíše do sessionStorage případě, že se zde uživatel proklikne z nastavení kampaně při chybějícím description
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        // Převod query parametrů na objekt
        const paramsObject = Object.fromEntries(queryParams.entries());

        if (paramsObject?.uuid) {
            console.log(paramsObject?.uuid);

            // Uložení uuid do sessionStorage
            sessionStorage.setItem("x23uU09I098D", paramsObject?.uuid);

            // Odstranění parametru 'uuid' z URL
            queryParams.delete('uuid');

            // Aktualizace URL bez znovunačtení (pouze pokud zbydou nějaké parametry)
            const newQueryString = queryParams.toString();
            const newUrl = newQueryString ? `${window.location.pathname}?${newQueryString}` : window.location.pathname;

            window.history.replaceState(null, '', newUrl);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            setShopForDisplay(userData?.shops?.find(oneShop => oneShop.uuid === uuid));
        }
    }, [uuid, shopForDisplay]);

    useEffect(() => {
        if (Object.keys(shopForDisplay).length > 0) {
            setProductFeedUrl(shopForDisplay.feed);
            setDescValue(shopForDisplay.description)
        } else if (selectedShop) {
            setProductFeedUrl(selectedShop.feed)
            setDescValue(selectedShop.description)
        }
    }, [shopForDisplay, selectedShop]);

    useEffect(() => {
        if (pno < 5) {
            setRangeValue(5)
        } else {
            setRangeValue(pno);
        }
    }, [pno]);

    const validateUrl = (url) => {
        const urlPattern = /^https?:\/\/.*\..*/i;
        return urlPattern.test(url);
    };

    useEffect(() => {
        setLimitValue(budget);
    }, [budget]);

    useEffect(() => {
        if (selectedShop && selectedShop.currency) {
            setCurrency(selectedShop.currency);
        }
    }, [selectedShop]);

    const handleCodeCopy = () => {
        if (codeVisible) {
            navigator.clipboard.writeText(code).then(() => {
                showAlert(t('appSettings.codeCopied'), "info");
                copyButtonRef.current.blur();
            });
        }
    };

    const currencyType = () => {
        if (selectedShop && selectedShop?.currency) {

        }
    }

    const handleRangeChange = (e) => {
        setRangeValue(e.target.value);
    };

    const handleFeedUrlChange = (e) => {
        const newUrl = e.target.value;
        setProductFeedUrl(newUrl);

        if (!validateUrl(newUrl)) {
            setFeedUrlError(t('appSettings.invalidUrl'));
        } else {
            setFeedUrlError('');
        }
    };

    const handleLimitChange = (e) => {
        setLimitValue(e.target.value);
    };




    const handleManagerEmailChange = (e) => {
        setManagerEmail(e.target.value);
    };

    const handleUserRemove = (email) => {
        setUserList(userList.filter(user => user !== email));
    };

    const toggleCodeVisibility = () => {
        setCodeVisible(!codeVisible);
    };

    const validateFeed = async (event) => {
        event.preventDefault();

        if (!validateUrl(productFeedUrl)) {
            showAlert(t('appSettings.invalidUrl'), "danger");
            setFeedUrlError(t('appSettings.invalidUrl'));
            return;
        }

        try {
            setIsUserDataLoading(true);
            const response = await axiosInstance.post('validate-xml-feed/', { feed: productFeedUrl });

            if (response.data.status === true) {
                try {
                    const feedUpdateResponse = await axiosInstance.patch(`shop/update/${uuid}/`, { feed: productFeedUrl });
                    setIsUserDataLoading(false);
                } catch (error) {
                    showAlert(t('appSettings.feedSubmissionError'), "danger");
                    setIsUserDataLoading(false);
                }

                try {
                    const merchantUpdateResponse = await axiosInstance.patch("https://permoniq.com/api/googlemerchant/account/feed/update/", {
                        account_id: shopForDisplay?.google_merchant,
                        feed_name: shopForDisplay?.name,
                        feed_url: productFeedUrl
                    });
                    setIsUserDataLoading(false);
                } catch (error) {
                    showAlert(t('appSettings.feedSubmissionError'), "danger");
                    setIsUserDataLoading(false);
                }
            }
        } catch (error) {
            showAlert(t('appSettings.feedSubmissionError'), "danger");
            setIsUserDataLoading(false);
        }
    };

    const updatePNO = async (event) => {
        event.preventDefault();
        event.target.blur();
        setIsUserDataLoading(true);

        try {
            const updatePNOResponse = await axiosInstance.patch(`shop/update/${selectedShop ? selectedShop.uuid : userData.shops[0].uuid}/`, { pno: rangeValue });
            setPno(rangeValue);
            setIsUserDataLoading(false);
            showAlert(t("appSettings.pnoSet"), "info")
            if (campaignId) {
                try {
                    const updateCampaignRoasResponse = await axiosInstance.patch(`campaign/${campaignId}/update/`, { roas: pno === 0 ? 0 : Number((100 / pno).toFixed(2)) });
                } catch (error) {
                    showAlert(t('appSettings.pnoUpdateError'), "danger");
                }
            }
        } catch (error) {
            showAlert(t('appSettings.pnoUpdateError'), "danger");
            setIsUserDataLoading(false);
        }
    };

    const updateLimit = async (event) => {
        event.preventDefault();
        const minLimit = currentCurrencySettings.minLimit;
        const maxLimit = currentCurrencySettings.maxLimit;
        const limit = Number(limitValue);

        if (isNaN(limit)) {
            showAlert(t("appSettings.invalidNumber"), "danger");
            return;
        }

        if (limit < minLimit) {
            showAlert(t("appSettings.limitLow", { minLimit, currency: currentCurrencySettings.symbol }), "danger");
            return;
        }

        if (limit > maxLimit) {
            showAlert(t("appSettings.limitExceeded", { maxLimit, currency: currentCurrencySettings.symbol }), "danger");
            return;
        }

        setIsUserDataLoading(true);
        try {
            const updateLimitResponse = await axiosInstance.patch(`shop/update/${uuid}/`, { budget: limit });
            setBudget(limit);
            setIsUserDataLoading(false);
            showAlert(t("appSettings.limitSet"), "info");
        } catch (error) {
            showAlert(t('appSettings.limitUpdateError'), "danger");
            setIsUserDataLoading(false);
        }
    };


    const onUpdateDesc = async (e) => {
        e.preventDefault()

        try {
            const onUpdateDescResponse = await axiosInstance.patch(`shop/update/${uuid}/`, {
                description: descValue
            })
            showAlert(t("appSettings.updateDescSuccess", "info"))
        } catch (error) {
            console.log("Update description error: ", error)
            showAlert(t("appSettings.updateDescError"), "danger")
        }
    }

    return (
        <>
            {isUserDataLoading && <ModalLoading />}
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            <article className="app-content">
                <h1>{t('appSettings.shopSettings')}</h1>
                <div className="app-content__row">
                    <div className="box">
                        <h2>{t('appSettings.targetPnoTitle')}</h2>
                        <form action="#" method="post" autoComplete='off' className="form">
                            {
                                pno > 0 &&
                                <p><strong>{t('appSettings.currentPno', { pno: pno })}</strong></p>
                            }
                            <p className="snuggle">

                                {t('appSettings.maxPnoExplanation', {
                                    rangeValue,
                                    baseAmount: budget * 30,
                                    currency: currentCurrencySettings.symbol,
                                    turnover: (() => {
                                        let baseAmount = currentCurrencySettings.baseAmount;
                                        let result = ((budget * 30) / rangeValue) * 100;
                                        let formattedResult = new Intl.NumberFormat('cs-CZ', {
                                            style: 'currency',
                                            currency: currency
                                        }).format(result);
                                        
                                        return formattedResult;
                                    })()
                                })}
                                <sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub>
                            </p>

                            <p className="range js-range">
                                <input type="range" id="range" name="range" min="5" max="50" value={rangeValue} step="1" onChange={handleRangeChange} />
                                <span className="range__values">
                                    <span>5,00&nbsp;%</span>
                                    <span>50,00&nbsp;%</span>
                                </span>
                            </p>
                            <p>{t('appSettings.pnoChangeAdvice')}</p>
                            <p><button className="btn" onClick={(event) => updatePNO(event)}>{pno === 0 ? t('appSettings.savePNO') : t('appSettings.save')}</button></p>
                        </form>
                    </div>

                    <div className="box">
                        <h2>{t('appSettings.limitTitle')}</h2>
                        <p>{t('appSettings.limitExplanation')}</p>
                        <form action="#" autoComplete='off' method="post" className="form">
                            <p className="form__currency">
                                <label htmlFor="limit">{t('appSettings.limit')}</label>
                                <input
                                    type="number"
                                    value={limitValue}
                                    min={currentCurrencySettings.minLimit}
                                    max={currentCurrencySettings.maxLimit}
                                    name="limit"
                                    id="limit"
                                    onChange={handleLimitChange}
                                />
                                {currentCurrencySettings.symbol}
                            </p>

                            <p><button className="btn" onClick={(event) => updateLimit(event)}>{t('appSettings.save')}</button></p>
                        </form>
                    </div>
                </div>

                <div className="app-content__row">
                    <div className="box">
                        <h2>PermoniQ</h2>
                        <p>{t('appSettings.installCodeDescription')}</p>
                        <p>{t('appSettings.installCodeInstruction')}</p>
                        <div className={`snuggle form__hide ${codeVisible ? 'is-open' : ''}`}>
                            {codeVisible && (
                                <textarea name="code" id="code" className="form__code" cols="30" rows="4" value={code} onChange={(e) => setCode(e.target.value)} />
                            )}
                        </div>
                        <p className="snuggle">
                            <button className="btn js-copy" onClick={handleCodeCopy} ref={copyButtonRef} disabled={!codeVisible}>{t('appSettings.copyCode')}</button>
                            &nbsp;&nbsp;
                            <a href="#code" className="link js-form-show" onClick={toggleCodeVisibility}>
                                {codeVisible ? t('appSettings.hideCode') : t('appSettings.showCode')}
                            </a>
                        </p>
                        <p>{t('appSettings.testPixel')}</p>
                    </div>

                    <div className="box">
                        <h2>{t('appSettings.xmlFeedTitle')}</h2>
                        <form onSubmit={validateFeed} autoComplete='off' className="form">
                            <p><a href="#" className="link">{t('appSettings.howToConnectFeed')}</a></p>
                            {/* <p className="snuggle">{t('appSettings.currentFeedUrl', { feedUrl: productFeedUrl })}</p> */}
                            <p>
                                {t('appSettings.enterFeedUrl')}<br />
                                <input type="text" value={productFeedUrl} onChange={handleFeedUrlChange} />
                            </p>
                            {feedUrlError && <p style={{ color: 'red' }}>{feedUrlError}</p>}
                            <p>{t('appSettings.feedUrlNote')}</p>
                            <p><button className="btn" disabled={feedUrlError}>{t('appSettings.save')}</button></p>
                        </form>
                    </div>
                </div>

                <div className="app-content__row">
                    <div className="box" style={{ width: "100%" }}>
                        <h2>{t('appSettings.descTitle')}</h2>
                        <form onSubmit={onUpdateDesc} autoComplete='off' className="form">
                            <label htmlFor="about-us">
                                {t("registerForm.descAboutShop")}
                            </label>
                            <textarea
                                id="about-us"
                                rows="5"
                                cols="40"
                                placeholder={t("registerForm.descPlaceholder")}
                                maxLength={maxLength}
                                minLength={350}
                                value={descValue}
                                style={{ resize: "none" }}
                                onChange={(e) => setDescValue(e.target.value)}
                            />
                            <p style={{ textAlign: "right", margin: 0 }}>{350}/{maxLength} {t("appSettings.numOfLetters")}</p>
                            <p><button className="btn">{t('appSettings.sendDesc')}</button></p>
                        </form>
                    </div>
                    <sub style={{ color: 'red', fontSize: "1.5rem", marginTop: "2rem" }}>*<em style={{ color: "black", fontSize: "1rem" }}>{t("appSettings.baseAmountExplanation")}</em></sub>

                </div>
            </article>
        </>
    );
};

export default AppSettings;
