import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';

import { AppContext } from '../context/AppContext';  // Importujeme náš AppContext

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const FAQSection = () => {
    const { t } = useTranslation();
    const [openIndices, setOpenIndices] = useState([]);
    const [heights, setHeights] = useState({});
    const answerRefs = useRef([]);
    const { openModal } = useContext(AppContext);  // Použití funkce z kontextu pro otevření modalu

    const faqItems = [
        'spendingOverview',
        'googleAdsBonus',
        'supportedCountries',
        'adminAccess',
        'budgetAdherence'
    ];

    const handleToggle = (index) => {
        setOpenIndices((prevIndices) =>
            prevIndices.includes(index)
                ? prevIndices.filter((i) => i !== index)
                : [...prevIndices, index]
        );
    };

    useEffect(() => {
        const newHeights = {};
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                newHeights[index] = ref.scrollHeight;
            }
        });
        setHeights(newHeights);
    }, [openIndices]);

    return (
        <section className="section" id={t('faq.id')}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('faq.title'))}></h2>
                <div className="box faq">
                    {faqItems.map((item, index) => (
                        <div key={index} className={`faq__item ${openIndices.includes(index) ? 'is-open' : ''}`}>
                            <h3 className="faq__question" onClick={() => handleToggle(index)} dangerouslySetInnerHTML={renderHTML(t(`faq.items.${item}.question`))}></h3>
                            <div
                                className="faq__answer"
                                ref={(el) => (answerRefs.current[index] = el)}
                                style={{ maxHeight: openIndices.includes(index) ? `${heights[index]}px` : '0' }}
                                dangerouslySetInnerHTML={renderHTML(t(`faq.items.${item}.answer`))}
                            ></div>
                        </div>
                    ))}
                </div>
                <p className="section__more">
                    <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('faq.ctaTryFree'))}></a>
                    <a href={`#${t('paths.consultation')}`} className="btn btn--inverse js-modal" onClick={openModal} dangerouslySetInnerHTML={renderHTML(t('faq.ctaConsultation'))}></a>
                </p>
            </article>
        </section>
    );
};

export default FAQSection;
