import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import he from 'he';

import { AppContext } from '../context/AppContext';

import cssLogo from "../img/logos/logo-google-comparison-partner-v.png";
import dataplusLogo from "../img/logos/logo-dataplus.svg";

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const BenefitsSection = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(AppContext);

    return (
        <section className="section" id={t(`benefits.id`)}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('benefits.title'))}></h2>

                <div className="benefits">

                    <div className="box benefits__1">
                        <span className="icon icon--grow">
                            <svg className="icon__svg">
                                <use xlinkHref="/img/icons.svg#grow" />
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.grow.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.grow.description'))}></p>
                        <p>
                            <a href="https://www.advisio.cz/css-program/" target="_blank" rel="noopener noreferrer">
                                <img src={cssLogo} alt="logo Google Comparison Shopping Partner" width="100" height="19" />
                            </a>
                            <a href="https://dataplus.advisio.cz/" target="_blank" rel="noopener noreferrer">
                                <img src={dataplusLogo} alt="logo DataPlus" width="100" height="19" />
                            </a>
                        </p>
                    </div>

                    <div className="box is-connected benefits__2">
                        <span className="icon icon--shield">
                            <svg className="icon__svg">
                                <use xlinkHref="/img/icons.svg#shield" />
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.shield.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.shield.description'))}></p>
                    </div>

                    <div className="box is-connected benefits__3">
                        <span className="icon icon--meter">
                            <svg className="icon__svg">
                                <use xlinkHref="/img/icons.svg#meter" />
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.meter.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.meter.description'))}></p>
                    </div>

                    <div className="box is-connected has-children benefits__4">
                        <div className="benefits__item">
                            <span className="icon icon--heart">
                                <svg className="icon__svg">
                                    <use xlinkHref="/img/icons.svg#heart" />
                                </svg>
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.heart.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.heart.description'))}></p>
                        </div>

                        <div className="benefits__item">
                            <span className="icon icon--publish">
                                <svg className="icon__svg">
                                    <use xlinkHref="/img/icons.svg#publish" />
                                </svg>
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.publish.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.publish.description'))}></p>
                        </div>

                        <div className="benefits__item">
                            <span className="icon icon--chat">
                                <svg className="icon__svg">
                                    <use xlinkHref="/img/icons.svg#chat" />
                                </svg>
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.chat.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.chat.description'))}></p>
                        </div>
                    </div>
                </div>

                <p className="section__more">
                    <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('benefits.ctaTryFree'))}></a>
                    <a href={`#${t('paths.consultation')}`} className="btn btn--inverse js-modal" onClick={openModal} dangerouslySetInnerHTML={renderHTML(t('benefits.ctaConsultation'))}></a>
                </p>
            </article>
        </section>
    );
};

export default BenefitsSection;
