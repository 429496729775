import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { AppContext } from './context/AppContext';

import router from './routes';

const App = () => {
  const contextValue = useContext(AppContext)

  const {
    setIsLogged
  } = useContext(AppContext)

  if (sessionStorage.getItem("isLogged")) {
    setIsLogged(true)
  }

  useEffect(() => {
    // if (process.env.NODE_ENV === 'development') {
    //   window.appContext = Object.freeze(contextValue);
    // } 


    // Dočasně přítupné - smazat!!!
    window.appContext = Object.freeze(contextValue);

  })

  useEffect(() => {
    console.log(`version: ${process.env.REACT_APP_VERSION}`)
  }, [])

  return (
    <RouterProvider router={router} />
  );
};

export default App;
