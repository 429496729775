import React from 'react';

import closeIcon from "../img/icons/close.svg";

const SimpleAlert = ({ message, onClose, type = 'base' }) => {
    const alertTypeClass = {
        success: 'alert--success',
        info: 'alert--info',
        danger: 'alert--danger',
        base: 'alert--base',
    }[type] || 'alert--base';

    return (
        <div className="alerts" >
            <div className={`alert ${alertTypeClass} is-open`}>
                <div className="alert__message">
                    <p>{message}</p>
                </div>
                <div className="alert__close" onClick={onClose}>
                    <span className="icon icon--close">
                        <img src={closeIcon} className='icon__svg' alt="close icon" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SimpleAlert;

/*
Návod k použití komponenty SimpleAlert:

1. **Přidejte stav a funkce pro alert:**
   - Ve své komponentě, kde chcete zobrazovat upozornění, přidejte stav a funkce pro ovládání alertu:
     ```js
     const [isAlertVisible, setIsAlertVisible] = useState(false);
     const [alertMessage, setAlertMessage] = useState({ text: '', type: 'base' });

     const showAlert = (message, type = 'base') => {
         setAlertMessage({ text: message, type });
         setIsAlertVisible(true);
     };

     const closeAlert = () => {
         setIsAlertVisible(false);
     };
     ```

2. **Zobrazte SimpleAlert ve vaší komponentě:**
   - Použijte podmíněné zobrazení komponenty SimpleAlert:
     ```js
     {isAlertVisible && (
         <SimpleAlert
             message={alertMessage.text}
             onClose={closeAlert}
             type={alertMessage.type}
         />
     )}
     ```

3. **Zavolejte `showAlert` pro zobrazení upozornění:**
   - V reakci na události (například po chybě nebo úspěšné akci) zavolejte `showAlert`:
     ```js
     showAlert('Akce byla úspěšná!', 'success');
     ```

4. **Zavřete upozornění:**
   - Uživatel může zavřít alert kliknutím na ikonu zavření, což vyvolá funkci `onClose` a skryje upozornění.
*/
