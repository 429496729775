import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import he from 'he';

import { AppContext } from '../context/AppContext';

import axiosInstance from '../components/AxiosConfig';

import Cookies from 'js-cookie';

import SimpleAlert from '../components/SimpleAlert';

import googleLogo from '../img/login-google.svg';
import facebookLogo from '../img/login-facebook.svg';
import icons from '../img/icons.svg'; // Importujte soubor SVG s ikonami

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Přidejte useNavigate zde
  const { lng } = useParams(); // Přidejte useParams zde

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    userData,
    setUserData,
    alertMessage,
    isAlertVisible,
    showAlert,
    closeAlert,
    isLogged
  } = useContext(AppContext)

  // // useEffect pro přesměrování, jakmile je userData naplněno
  // useEffect(() => {
  //   if (Object.keys(userData).length > 0) {
  //     console.log("google user data", userData);
  //     window.scrollTo(0, 0);
  //     console.log("google login");
  //     navigate(`/${lng}/app/campaigns/`);
  //   }
  // }, [userData, navigate, lng]);

  const handleRegistrationClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/${lng}/registration-account/`);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get('user/detail/');
      console.log("Fetched user data:", response.data);
      setUserData(response.data);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      showAlert(`${error.response.data.detail}`, "danger")
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch('https://permoniq.com/api/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(t("loginPage.invalidCredentials"));
        }
        return response.json();
      })
      .then(data => {
        const { access, refresh, pk } = data;
        console.log("login data", data)

        // Nastavení secure cookies s životností 7 dní
        Cookies.set("aTkn_0yD9K", access, { secure: true, sameSite: 'None', expires: 1 });
        Cookies.set("utk_98x76", pk, { secure: true, sameSite: 'None', expires: 1 });
        Cookies.set("rTkn_4zF7P", refresh, { secure: true, sameSite: 'None', expires: 7 });

        window.scrollTo(0, 0);
        navigate(`/${lng}/app/register-shop/`);
        // Zavolejte funkci pro získání uživatelských dat po úspěšném získání tokenů
        // fetchUserData();
      })
      .catch(error => {
        console.error('Error:', error);
        showAlert(t("loginPage.invalidCredentials"), "danger");
        navigate(`/${lng}/login/`);

      });
  };

  const handleGoogleLogin = () => {
    const clientId = '388189105011-tvvt7fs4qr5eak5d382ti8mpjpjp85tg.apps.googleusercontent.com';
    const redirectUri = `${window.location.origin}/${lng}/auth/google/callback/`; // Adjust this based on your environment
    const scope = 'openid email profile';
    const responseType = 'code';

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&state=${redirectUri}`;

    console.log(googleLoginUrl)
    window.location.href = googleLoginUrl;
  }

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <section className="section login">
        <article className="container">
          <h1 className="title" dangerouslySetInnerHTML={renderHTML(t('loginPage.title'))}></h1>
          <form action="#" method="post" autoComplete='off' className="form" onSubmit={handleSubmit}>
            <div className="box">
              <p>
                <label htmlFor="email" dangerouslySetInnerHTML={renderHTML(t('loginPage.emailLabel'))}></label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required="required"
                  autoComplete='new-password'
                />
              </p>
              <p className="form__password">
                <label htmlFor="password" dangerouslySetInnerHTML={renderHTML(t('loginPage.passwordLabel'))}></label>
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required="required"
                  autoComplete='new-password'
                />
                <span className="icon icon--eye" onClick={togglePasswordVisibility}>
                  <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                  </svg>
                </span>
              </p>
              <p className="login__links">
                <a href="" className="link" dangerouslySetInnerHTML={renderHTML(t('loginPage.forgotPassword'))}></a>
              </p>
              <p>
                <button className="btn" type="submit">{t('loginPage.loginButton')}</button>
              </p>
              <p className="login__separator">
                <em dangerouslySetInnerHTML={renderHTML(t('loginPage.continueWith'))}></em>
              </p>
              <p>
                <a className="login__external" onClick={() => handleGoogleLogin()}>
                  <img src={googleLogo} alt="Google" />
                  {t('loginPage.googleLogin')}
                </a>
              </p>
              {/* <p>
              <a href="" className="login__external">
                <img src={facebookLogo} alt="Facebook" />
                {t('loginPage.facebookLogin')}
              </a>
            </p> */}
              <p className="login__links">
                {t('loginPage.noAccount')} <a href="" onClick={handleRegistrationClick} className="link" dangerouslySetInnerHTML={renderHTML(t('loginPage.register'))}></a>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );
};

export default Login;
