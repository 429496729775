import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import axiosInstance from '../components/AxiosConfig';

import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';
import ProgressTracker from '../components/ProgressTracker';

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

import useUserRegisterDetails from '../hooks/useUserRegisterDetails';

const flags = {
    cs: csFlag,
    en: enFlag,
    sk: skFlag,
    pl: plFlag,
    hu: huFlag,
    ro: roFlag,
};

const countryIcoFormats = {
    cs: /^\d{8}$/,
    sk: /^\d{8}$/,
    hu: /^\d{8}$/,
    pl: /^\d{10}$/,
    ro: /^\d{2,10}$/,
};

const CompanyDetails = () => {
    const { t } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        street: '',
        city: '',
        postal_code: '',
        ico: '',
        dic: '',
        email: '',
        country_billing: 'cs',
    });

    const [errors, setErrors] = useState({});
    const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);

    const {
        userData,
        uuid,
        steps,
        isAdmin,
        isAlertVisible,
        showAlert,
        closeAlert,
        alertMessage,
        selectedShop,
        setSelectedShop
    } = useContext(AppContext);

    useUserRegisterDetails()

    //   useEffect(() => {
    //     if (userData && userData.shops) {
    //       const activeShop = userData.shops.find(shop => shop.status_create !== 'end');
    //       if (activeShop) {
    //         setUrlID(activeShop.uuid); // Nastaví UUID obchodu
    //       } else {
    //         navigate(`/${lng}/app/register-shop/`);
    //       }
    //     }
    //   }, [userData, lng, navigate, t]);

    useEffect(() => {
        if (lng === 'en') {
            setFormData(prevState => ({ ...prevState, country_billing: 'cs' }));
        } else {
            setFormData(prevState => ({ ...prevState, country_billing: lng }));
        }
    }, [lng]);

    useEffect(() => {
        const getCompanyInfo = () => {
            if (Object.keys(userData).length > 0) {

                if (uuid) {
                    setSelectedShop(userData?.shops.find(oneShop => oneShop.uuid === uuid))

                    if (isAdmin) {
                        console.log("admin", isAdmin)
                        if (selectedShop) {
                            console.log("selected shop: ", selectedShop)
                            setFormData({
                                name: selectedShop.name,
                                street: selectedShop.street,
                                city: selectedShop.city,
                                postal_code: selectedShop.postal_code,
                                ico: selectedShop.ico,
                                dic: selectedShop.dic,
                                email: selectedShop.email,
                                country_billing: selectedShop.country_billing,
                            });
                        }
                    } else {
                        console.log(selectedShop)
                        if (selectedShop && selectedShop.status_create !== 'end') {
                            setFormData({
                                name: selectedShop.name,
                                street: selectedShop.street,
                                city: selectedShop.city,
                                postal_code: selectedShop.postal_code,
                                ico: selectedShop.ico,
                                dic: selectedShop.dic,
                                email: selectedShop.email,
                                country_billing: selectedShop.country_billing,
                            });
                        }
                    }

                };
            }
        }

        getCompanyInfo();

    }, [uuid, lng, navigate, t, selectedShop]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const sanitizedValue = (id === 'ico' || id === 'postal_code') ? value.replace(/\s+/g, '') : value; // Odstraní mezery pro ICO i postal_code
        setFormData(prevState => ({ ...prevState, [id]: sanitizedValue }));
        setErrors(prevState => ({ ...prevState, [id]: sanitizedValue.trim() === '' }));
    };

    const toggleCountry = () => {
        setIsCountrySelectOpen(!isCountrySelectOpen);
    };

    const handleCountryChange = (lang) => {
        setFormData(prevState => ({ ...prevState, country_billing: lang }));
        setIsCountrySelectOpen(false);
    };

    const validateFields = () => {
        const newErrors = {};
        const namePattern = /^[a-zA-Zá-žÁ-Ž\s]+$/;
        const zipPattern = /^[0-9]+$/;

        if (!formData.name || formData.name.trim() === '') {
            newErrors.name = true;
        }
        if (!formData.street || formData.street.trim() === '') {
            newErrors.street = true;
        }
        if (!formData.city || !namePattern.test(formData.city)) {
            newErrors.city = true;
        }
        if (!formData.postal_code || !zipPattern.test(formData.postal_code)) {
            newErrors.postal_code = true;
        }
        if (!formData.ico || !countryIcoFormats[formData.country_billing].test(formData.ico)) {
            newErrors.ico = true;
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateFields();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            showAlert(t('companyDetailsForm.fillAllFields'));
        } else {
            const submittedData = {
                ...formData,
                status_create: "shopdata",
            };

            try {
                await axiosInstance.patch(`shop/update/${uuid}/`, submittedData);
                navigate(`/${lng}/app/upload-products/`);
            } catch (error) {
                showAlert(t('companyDetailsForm.updateFailed'));
            }
        }
    };

    // Přizpůsobení labels v steps
    const localizedSteps = steps.map(step => ({
        ...step,
        label: t(`${step.label}`),
        alertMessage: t(step.alertMessage),
    }));

    return (
        <>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={() => showAlert(t("companyDetailsForm.contactSupport"))} className="is-checked"><strong>1.</strong> {t('companyDetailsProgress.addShop')}</li>
                        <li onClick={() => showAlert(t("companyDetailsForm.contactSupport"))} className="is-active"><strong>2.</strong> {t('companyDetailsProgress.companyDetails')}</li>
                        <li onClick={() => showAlert(t("companyDetailsForm.contactSupport"))}><strong>3.</strong> {t('companyDetailsProgress.uploadProducts')}</li>
                        <li onClick={() => showAlert(t("companyDetailsForm.contactSupport"))}><strong>4.</strong> {t('companyDetailsProgress.connectGoogle')}</li>
                        <li onClick={() => showAlert(t("companyDetailsForm.contactSupport"))}><strong>5.</strong> {t('companyDetailsProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

            <ProgressTracker steps={localizedSteps} />

            <section className="section register">
                <article className="container">
                    <h1 className="title">{t('companyDetailsTitle')}</h1>
                    <form action="#" method="post" autoComplete='off' className="form" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="form__column">
                                <p>
                                    <label htmlFor="name">{t('companyDetailsForm.company')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input type="text" id="name" value={formData.name} onChange={handleInputChange} />
                                    {errors.name && <span className="error">{t('companyDetailsForm.required')}</span>}
                                </p>
                                <p>
                                    <label htmlFor="street">{t('companyDetailsForm.street')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input type="text" id="street" value={formData.street} onChange={handleInputChange} />
                                    {errors.street && <span className="error">{t('companyDetailsForm.required')}</span>}
                                </p>
                            </div>
                            <div className="form__column">
                                <p>
                                    <label htmlFor="city">{t('companyDetailsForm.city')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input type="text" id="city" value={formData.city} onChange={handleInputChange} />
                                    {errors.city && <span className="error">{t('companyDetailsForm.requiredLetters')}</span>}
                                </p>
                                <p>
                                    <label htmlFor="postal_code">{t('companyDetailsForm.zip')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input type="text" id="postal_code" value={formData.postal_code} onChange={handleInputChange} />
                                    {errors.postal_code && <span className="error">{t('companyDetailsForm.requiredNumbers')}</span>}
                                </p>
                            </div>
                            <div className="form__column">
                                <p>
                                    <label htmlFor="ico">{t('companyDetailsForm.id')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input type="text" id="ico" value={formData.ico} onChange={handleInputChange} />
                                    {errors.ico && <span className="error">{t(`companyDetailsForm.requiredId.${formData.country_billing}`)}</span>}
                                </p>
                                <p>
                                    <label htmlFor="dic">{t('companyDetailsForm.taxid')}<sub style={{ color: 'red', fontSize: "1.5rem" }}></sub></label>
                                    <input type="text" id="dic" value={formData.dic} onChange={handleInputChange} />
                                </p>
                            </div>
                            <div className="form__column">
                                <div className="form__p">
                                    <label htmlFor="country">{t('companyDetailsForm.country')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <div className={`country ${isCountrySelectOpen ? 'is-open' : ''}`} onClick={toggleCountry}>
                                        <div className="country__selected" data-lang={formData.country_billing}>{t(`companyDetailsForm.countries.${formData.country_billing}`)}</div>
                                        <ul className="country__switch">
                                            {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                                <li data-lang={lang} key={lang} onClick={() => handleCountryChange(lang)}>{t(`companyDetailsForm.countries.${lang}`)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <select name="country" id="country" className="country__select" value={formData.country_billing} onChange={(e) => setFormData(prevState => ({ ...prevState, country_billing: e.target.value }))}>
                                        {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                            <option value={lang} key={lang}>{t(`companyDetailsForm.countries.${lang}`)}</option>
                                        ))}
                                    </select>
                                </div>
                                <p>
                                    <label htmlFor="email">{t('companyDetailsForm.email')}<sub style={{ color: 'red', fontSize: "1.5rem" }}></sub></label>
                                    <input type="text" id="email" value={formData.email} onChange={handleInputChange} />
                                </p>
                            </div>
                            <sub style={{ color: 'red', fontSize: "1.5rem" }}>*<em style={{ color: "black", fontSize: "1rem" }}>{t("requireItem")}</em></sub>
                        </div>
                        <div className="register__submit">
                            <p>
                                <button className="btn" type="submit">{t('companyDetailsForm.submit')}</button>
                            </p>
                        </div>
                    </form>
                </article>
            </section>
        </>
    );
};

export default CompanyDetails;
