import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';

import { AppContext } from '../context/AppContext';

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const ForWhomSection = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(AppContext);

    return (
        <section className="section" id={t('paths.forWhom')}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('forWhom.title'))}></h2>
                <div className="who">
                    <div className="box box--back who__1">
                        <span className="icon icon--basket">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#basket" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.smallMediumLarge.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.smallMediumLarge.description'))}></p>
                    </div>
                    <div className="box box--back is-connected who__2">
                        <span className="icon icon--open">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#open" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.newEstablished.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.newEstablished.description'))}></p>
                    </div>
                    <div className="box box--back is-connected who__3">
                        <span className="icon icon--document">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#document" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.eshops.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.eshops.description'))}></p>
                    </div>
                </div>
                <p className="section__more">
                    <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('forWhom.ctaTryFree'))}></a>
                    <a href={`#${t('paths.consultation')}`} className="btn btn--inverse js-modal" onClick={openModal} dangerouslySetInnerHTML={renderHTML(t('forWhom.ctaConsultation'))}></a>
                </p>
            </article>
        </section>
    );
};

export default ForWhomSection;
