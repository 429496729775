import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SHA256 from 'crypto-js/sha256';

import he from 'he';

import { AppContext } from '../context/AppContext';

import AxiosInstance from './AxiosConfig';

import Cookies from 'js-cookie';

import SimpleAlert from './SimpleAlert';

import googleLogo from "../img/login-google.svg";
import facebookLogo from "../img/login-facebook.svg";
import contactPerson from "../img/contact-person.png";
import icons from '../img/icons.svg';

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const TryPermoniQSection = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password2Visible, setPassword2Visible] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isPasswordLettersValid, setIsPasswordLettersValid] = useState(false);
  const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  window.dataLayer = window.dataLayer || []

  const {
    openModal,
    alertMessage,
    isAlertVisible,
    showAlert,
    closeAlert
  } = useContext(AppContext);

  useEffect(() => {
    setIsPasswordLengthValid(password.length >= 8);
    setIsPasswordLettersValid(/[a-z]/.test(password) && /[A-Z]/.test(password));
    setIsPasswordNumberValid(/\d/.test(password));
    setDoPasswordsMatch(password !== '' && password2 !== '' && password === password2);
  }, [password, password2]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const hashedEmail = SHA256(email).toString();

    fetch("https://permoniq.com/api/user/create/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
        login_provider: "own"
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(t("loginPage.invalidCredentials"));
        }
        return response.json();
      })
      .then(data => {

        const { access, refresh } = data;
        console.log(data)

        // Nastavení secure cookies s životností 7 dní
        Cookies.set("aTkn_0yD9K", access, { secure: true, sameSite: 'None', expires: 1 });
        Cookies.set("rTkn_4zF7P", refresh, { secure: true, sameSite: 'None', expires: 7 });

        window.dataLayer.push({
          event: "register_user",
          user: {
            email: email,
            hashed_email: hashedEmail
          }
        })

        window.scrollTo(0, 0);

        navigate(`/${lng}/app/register-shop/`);

      })
      .catch(error => {
        // Obecné informace o chybě
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
        console.log(error)

        // Další informace specifické pro Axios
        if (error.response) {
          // Server odpověděl s chybovým kódem
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          // Žádost byla odeslána, ale nedostali jsme žádnou odpověď
          console.error('Error request:', error.request);
        }

        if (error.response.data.email[0] === "Položka aa_user s touto hodnotou v poli email již existuje.") {
          console.log(1)
          showAlert("Uživatel s tímto e-mailem již existuje.", "danger")
        } else {
          console.log(2)
          showAlert(`${error.message}`, "danger");
        }
      });
  };


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePassword2Visibility = () => {
    setPassword2Visible(!password2Visible);
  };

  const handleGoogleLogin = () => {
    const clientId = '388189105011-tvvt7fs4qr5eak5d382ti8mpjpjp85tg.apps.googleusercontent.com';
    const redirectUri = `${window.location.origin}/${lng}/auth/google/callback/`; // Adjust this based on your environment
    const scope = 'openid email profile';
    const responseType = 'code';

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&state=${redirectUri}`;

    console.log(googleLoginUrl)
    window.location.href = googleLoginUrl;
  }

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <section className="section" id={t('paths.tryFree')}>
        <article className="container">
          <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.title'))}></h2>
          <div className="contact">
            <div className="box contact__1">
              <form method="post" autoComplete='off' className="form form--register" onSubmit={handleSubmit}>
                <p>
                  <label htmlFor="email" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.emailLabel'))}></label>
                  <input type="email" id="email" autoComplete='new-password' value={email} onChange={(e) => setEmail(e.target.value)} required />
                </p>
                <div className="form__column">
                  <p className="form__password">
                    <label htmlFor="password" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordLabel'))}></label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete='new-password'
                    />
                    <span className="icon icon--eye" onClick={togglePasswordVisibility}>
                      <svg className="icon__svg">
                        <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                      </svg>
                    </span>
                  </p>
                  <p className="form__password">
                    <label htmlFor="password-2" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordRepeatLabel'))}></label>
                    <input
                      type={password2Visible ? "text" : "password"}
                      id="password-2"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                      autoComplete='new-password'
                    />
                    <span className="icon icon--eye" onClick={togglePassword2Visibility}>
                      <svg className="icon__svg">
                        <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                      </svg>
                    </span>
                  </p>
                </div>
                <div className="form__column">
                  <ul className="form__check">
                    <li className={isPasswordLengthValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.length'))}></li>
                    <li className={isPasswordLettersValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.letters'))}></li>
                    <li className={isPasswordNumberValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.number'))}></li>
                  </ul>
                  <ul className="form__check">
                    <li className={doPasswordsMatch ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.match'))}></li>
                  </ul>
                </div>
                <p>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="gdpr"
                      id="gdpr"
                      checked={isCheckboxChecked}
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                      required
                    />
                    <span className="checkbox__label" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.gdprLabel'))}></span>
                    <span className="checkbox__checker"></span>
                  </label>
                </p>
                <p className="form__submit">
                  <input type="submit" value={t('tryFreeSection.form.submitButton')} className="btn" disabled={!isCheckboxChecked} />
                  <em dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.orContinueWith'))}></em>
                  <span className="form__login">
                    <a style={{ cursor: "pointer" }} onClick={() => handleGoogleLogin()}><img src={googleLogo} alt="Google" /></a>
                    {/* <a href="#"><img src={facebookLogo} alt="Facebook" /></a> */}
                  </span>
                </p>
              </form>
            </div>
            <div className="box is-connected contact__2">
              <ul className="list">
                <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.freeMonth'))}></li>
                <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.easySetup'))}></li>
                <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.simpleEvaluation'))}></li>
                <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.supportTeam'))}></li>
              </ul>
            </div>
            <div className="box box--back contact__3">
              <p className="contact__person"><img src={contactPerson} alt="" width="120" height="137" loading="lazy" /></p>
              <p className="contact__item">
                <span className="icon icon--phone">
                  <svg className="icon__svg">
                    <use xlinkHref={`${icons}#phone`} x="0" y="0" width="100%" height="100%"></use>
                  </svg>
                </span>
                <a href={t("permoniqPhoneHref")}>{t("permoniqPhone")}</a>
                <small dangerouslySetInnerHTML={renderHTML(t('footerSection.contacts.phoneHours'))}></small>
              </p>
              <p className="contact__item">
                <span className="icon icon--email">
                  <svg className="icon__svg">
                    <use xlinkHref={`${icons}#email`} x="0" y="0" width="100%" height="100%"></use>
                  </svg>
                </span>
                <a href="mailto:info@permoniq.com">info@permoniq.com</a>
              </p>
              <p><a href={`#${t('paths.consultation')}`} className="btn js-modal" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.consultationButton'))} onClick={openModal}></a></p>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default TryPermoniQSection;
