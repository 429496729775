import React from 'react';
import { useTranslation } from 'react-i18next';

const AppHelp = () => {
    const { t } = useTranslation();

    return (
        <article className="app-content">
            <h1>{t('appHelp.title')}</h1>

            <section className="help-section">
                <h2>{t('appHelp.uploadProducts.title')}</h2>
                <p>{t('appHelp.uploadProducts.description')}</p>
            </section>

            <section className="help-section">
                <h2>{t('appHelp.dataplus.title')}</h2>
                <p>{t('appHelp.dataplus.description1')}</p>
                <p>{t('appHelp.dataplus.description2')}</p>
            </section>

            <section className="help-section">
                <h2>{t('appHelp.adCredit.title')}</h2>
                <p>{t('appHelp.adCredit.description')}</p>
            </section>

            <section className="help-section">
                <h2>{t('appHelp.adFormats.title')}</h2>
                <p>{t('appHelp.adFormats.description')}</p>
            </section>

            <section className="help-section">
                <h2>{t('appHelp.usefulSettings.title')}</h2>
                <h4>{t('appHelp.usefulSettings.maxPnoTitle')}</h4>
                <p>{t('appHelp.usefulSettings.maxPnoDescription')}</p>
            </section>

            <section className="help-section">
                <h2>{t('appHelp.performanceEvaluation.title')}</h2>
                <p>{t('appHelp.performanceEvaluation.description1')}</p>
                <p>{t('appHelp.performanceEvaluation.description2')}</p>
                <p>{t('appHelp.performanceEvaluation.description3')}</p>
            </section>
        </article>
    );
};

export default AppHelp;
