import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const RedirectWithSlash = ({ children }) => {
    const location = useLocation();
    const pathWithSlash = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;

    if (!location.pathname.endsWith('/')) {
        return <Navigate to={pathWithSlash} replace />;
    }

    return children;
};

export default RedirectWithSlash;
