import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';
import ProgressTracker from '../components/ProgressTracker';

import axiosInstance from '../components/AxiosConfig';

import shoptetLogo from '../img/logos/logo-shoptet.png';
import upgatesLogo from '../img/logos/logo-upgates.png';
import shopifyLogo from '../img/logos/logo-shopify.png';
import woocommerceLogo from '../img/logos/logo-woocommerce.png';
import prestashopLogo from '../img/logos/logo-prestashop.png';
import eshopRychleLogo from '../img/logos/logo-eshop-rychle.png';
import useUserRegisterDetails from '../hooks/useUserRegisterDetails';

const UploadProducts = () => {
    const { t } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();

    const [openIndices, setOpenIndices] = useState([]);
    const [heights, setHeights] = useState({});
    const [feed, setFeed] = useState('');

    const {
        uuid,
        steps,
        isAdmin,
        isAlertVisible,
        showAlert,
        closeAlert,
        alertMessage,
        setIsUserDataLoading
    } = useContext(AppContext);

    const answerRefs = useRef([]);

    useUserRegisterDetails()

    useEffect(() => {
        const fetchFeed = async () => {
            if (uuid) {
                try {
                    const response = await axiosInstance.get(`shop/detail/${uuid}/`);
                    const data = response.data;
                    setFeed(data.feed);
                } catch (error) {
                    if (!isAdmin) {
                        console.error(error);
                        navigate(`/${lng}/app/register-shop/`);
                    }
                }
            }
        };
        fetchFeed();
    }, [uuid, navigate, lng]);

    let shoptetSteps = t('uploadProductsForm.shoptet.instructions', { returnObjects: true });
    let upgatesSteps = t('uploadProductsForm.upgates.instructions', { returnObjects: true });
    let shopifySteps = t('uploadProductsForm.shopify.instructions', { returnObjects: true });
    let woocommerceSteps = t('uploadProductsForm.woocommerce.instructions', { returnObjects: true });
    let prestashopSteps = t('uploadProductsForm.prestashop.instructions', { returnObjects: true });
    let eshopRychleSteps = t('uploadProductsForm.eshop-rychle.instructions', { returnObjects: true });

    shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
    upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
    shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
    woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
    prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
    eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];

    const validateUrl = (url) => {
        const urlPattern = /^https?:\/\/.*\..*/i;
        return urlPattern.test(url);
    };

    const handleInputChange = (e) => {
        setFeed(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUserDataLoading(true)
        if (feed && validateUrl(feed)) {
            const submittedData = {
                feed,
                status_create: "account"
            };
            try {
                const response = await axiosInstance.post('https://permoniq.com/api/validate-xml-feed/', submittedData);
                if (response.data.status === true) {
                    await axiosInstance.put(`shop/update/${uuid}/`, submittedData);
                    window.scrollTo(0, 0);
                    navigate(`/${lng}/app/connect-google/`);
                } else {
                    showAlert(t('uploadProductsForm.updateFailed'));
                }
            } catch (error) {
                console.error(error);
                showAlert(t('uploadProductsForm.updateFailed'));
            } finally {
                setIsUserDataLoading(false)
            }
        } else {
            showAlert(t('uploadProductsForm.fillAllFields'));
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        navigate(`/${lng}/app/company-details/`);
    };

    const handleToggle = (index) => {
        setOpenIndices((prevIndices) =>
            prevIndices.includes(index)
                ? prevIndices.filter((i) => i !== index)
                : [...prevIndices, index]
        );
    };

    useEffect(() => {
        const newHeights = {};
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                newHeights[index] = ref.scrollHeight;
            }
        });
        setHeights(newHeights);
    }, []);

    useEffect(() => {
        setHeights((prevHeights) => {
            const newHeights = { ...prevHeights };
            openIndices.forEach((index) => {
                if (answerRefs.current[index]) {
                    newHeights[index] = answerRefs.current[index].scrollHeight;
                }
            });
            return newHeights;
        });
    }, [openIndices]);

    // Přizpůsobení labels v steps
    const localizedSteps = steps.map(step => ({
        ...step,
        label: t(`${step.label}`),
        alertMessage: t(step.alertMessage),
    }));

    return (
        <>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-checked"><strong>1.</strong> {t('uploadProductsProgress.addShop')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-checked"><strong>2.</strong> {t('uploadProductsProgress.companyDetails')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-active"><strong>3.</strong> {t('uploadProductsProgress.uploadProducts')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))}><strong>4.</strong> {t('uploadProductsProgress.connectGoogle')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))}><strong>5.</strong> {t('uploadProductsProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

            <ProgressTracker steps={localizedSteps} />

            <section className="section register">
                <article className="container">
                    <h1 className="title">{t('uploadProductsTitle')}</h1>
                    <form onSubmit={handleSubmit} autoComplete='off' className="form">
                        <input type="hidden" id="uuid" value={uuid} />

                        <div className="box">
                            <p>
                                <label htmlFor="feed">{t('uploadProductsForm.feedUrl')}</label>
                                <input
                                    type="text"
                                    id="feed"
                                    value={feed}
                                    onChange={handleInputChange}
                                    required
                                />
                            </p>

                            <br />

                            <p style={{ fontWeight: 600 }}>{t('uploadProductsForm.whereToFindFeed')}</p>
                            <p>
                                {t('uploadProductsForm.whereToFindFeedAnswer')}
                            </p>

                            <div className="faq faq--compact">
                                <div className={`faq__item ${openIndices.includes(0) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(0)}>{t('uploadProductsForm.generalInstructions')}</h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[0] = el)}
                                        style={{ maxHeight: openIndices.includes(0) ? `${heights[0]}px` : '0' }}
                                    >
                                        <p>
                                            {t('uploadProductsForm.faqGeneralAnswer1')}
                                        </p>
                                        <p>
                                            {t('uploadProductsForm.faqGeneralAnswer2')}
                                        </p>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(1) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(1)}>
                                        <img className='logo__icon' src={shoptetLogo} alt={t('uploadProductsForm.shoptet.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[1] = el)}
                                        style={{ maxHeight: openIndices.includes(1) ? `${heights[1]}px` : '0' }}
                                    >
                                        <ol>
                                            {shoptetSteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(2) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(2)}>
                                        <img className='logo__icon' src={upgatesLogo} alt={t('uploadProductsForm.upgates.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[2] = el)}
                                        style={{ maxHeight: openIndices.includes(2) ? `${heights[2]}px` : '0' }}
                                    >
                                        <ol>
                                            {upgatesSteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(3) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(3)}>
                                        <img className='logo__icon' src={shopifyLogo} alt={t('uploadProductsForm.shopify.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[3] = el)}
                                        style={{ maxHeight: openIndices.includes(3) ? `${heights[3]}px` : '0' }}
                                    >
                                        <ol>
                                            {shopifySteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(4) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(4)}>
                                        <img className='logo__icon' src={woocommerceLogo} alt={t('uploadProductsForm.woocommerce.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[4] = el)}
                                        style={{ maxHeight: openIndices.includes(4) ? `${heights[4]}px` : '0' }}
                                    >
                                        <ol>
                                            {woocommerceSteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(5) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(5)}>
                                        <img className='logo__icon' src={prestashopLogo} alt={t('uploadProductsForm.prestashop.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[5] = el)}
                                        style={{ maxHeight: openIndices.includes(5) ? `${heights[5]}px` : '0' }}
                                    >
                                        <ol>
                                            {prestashopSteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className={`faq__item ${openIndices.includes(6) ? 'is-open' : ''}`}>
                                    <h3 className="faq__question" onClick={() => handleToggle(6)}>
                                        <img className='logo__icon' src={eshopRychleLogo} alt={t('uploadProductsForm.eshop-rychle.name')} />
                                    </h3>
                                    <div
                                        className="faq__answer"
                                        ref={(el) => (answerRefs.current[6] = el)}
                                        style={{ maxHeight: openIndices.includes(6) ? `${heights[6]}px` : '0' }}
                                    >
                                        <ol>
                                            {eshopRychleSteps.map((step, index) => (
                                                <li key={index}>{step}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="register__submit">
                            <p>
                                <button type="submit" className="btn">{t('uploadProductsForm.submit')}</button>
                            </p>
                        </div>
                    </form>
                </article>
            </section>
        </>
    );
};

export default UploadProducts;
